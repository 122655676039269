.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.brand-logo{
  height: 30px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* aws auth page */
.amplify-button--primary{
  background-color: #356CB6 !important;
  color: white !important;
}

.amplify-button--primary:hover{
  background-color: #225395 !important;
  color: white !important;
}

.amplify-button--link{
  color: #356CB6 !important;
}

.amplify-tabs__item--active {
  color: #356CB6 !important;
  border-top: 2px solid #356CB6 !important;
}

/* Map box legend (draw and trash) style  */
.mapboxgl-ctrl-group {
  margin-right: 20px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
}

.mapbox-gl-draw_polygon {
  background-size: 40px 40px !important;
  width: 60px !important;
  height: 60px !important;
  font-size: 100px !important;
}
.mapbox-gl-draw_trash {
  background-size: 40px 40px !important;
  width: 60px !important;
  height: 60px !important;
  font-size: 100px !important;
}
/* Map draw and trash heading */
.mapbox-gl-draw_ctrl-draw-heading {
  width: 60px;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  margin: 15px 17.6px -10px 0;
  background-color: #fff;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
